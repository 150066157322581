






import Vue from 'vue'
import EmailSanitizeList from '@/components/EmailSanitizeList.vue' // @ is an alias to /src

export default Vue.extend({
  name: 'Home',
  components: {
    EmailSanitizeList,
  },
})
