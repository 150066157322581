/* eslint-disable prettier/prettier */
export const roleAddresses: string[] = [
'mail',
'info',
'director',
'biz',
'support',
'sales',
'hostmaster',
'emails',
'contact',
'tech',
'webmaster',
'editor',
'service',
'shop',
'assistant',
'news',
'dev',
'CustomerService',
'business',
'cc',
'design',
'network',
'admin',
'hi',
'requests',
'management',
'author',
'null',
'office',
'manager',
'awareness',
'partners',
'production',
'orders',
'connect',
'accounting',
'questions',
'pro',
'Ad',
'cv',
'booking',
'media',
'socialmedia',
'webdev',
'dns',
'abuse',
'postmaster',
'billing',
'help',
'noc',
'people',
'hello'
]