
































































































import Vue from 'vue'
import firebase from 'firebase/app'
// import 'firebase/analytics'
// import 'firebase/auth'
// import 'firebase/firestore'
import 'firebase/functions'
import { mapGetters, mapActions } from 'vuex'
import { badDomains } from '@/libs/BadDomains'
import { badTlds } from '@/libs/BadTlds'
import { disposableDomains } from '@/libs/DisposableDomains'
import { roleAddresses } from '@/libs/RoleAddresses'

interface EmailObject {
  email: string
  handle: string
  domain: string
  tld: string | undefined
  error: string
}

export default Vue.extend({
  name: 'SanitizeList',
  data: (): {
    csvImport: File | null
    progressMessage: string
    emailListRaw: string[]
    goodEmails: EmailObject[]
    badEmails: EmailObject[]
    badEmailTypeCount: {
      badFormat: number
      duplicate: number
      supportRole: number
      badTldList: number
      badDomainList: number
      disposableDomainList: 0
      badMXRecord: number
    }
    isRunning: boolean
    sampleLimit: number
  } => {
    return {
      csvImport: null,
      progressMessage: 'Not started',
      emailListRaw: [],
      goodEmails: [],
      badEmails: [],
      badEmailTypeCount: {
        badFormat: 0,
        duplicate: 0,
        supportRole: 0,
        badTldList: 0,
        badDomainList: 0,
        disposableDomainList: 0,
        badMXRecord: 0,
      },
      isRunning: false,
      sampleLimit: 5,
    }
  },
  computed: {
    ...mapGetters([]),
    emailListLimited() {
      return this.emailListRaw.slice(0, this.sampleLimit)
    },
    badEmailsLimited() {
      return this.badEmails.slice(0, this.sampleLimit)
    },
  },
  methods: {
    ...mapActions([]),
    generateEmails() {
      for (let i = 0; i < 50; i++) {
        this.emailListRaw.push('anemail@adoggggggggggamin.com')
      }
      this.emailListRaw.push('email.dot@adoamin.com')
      this.emailListRaw.push('allbademail')
      this.emailListRaw.push('ben@gnail.com')
      this.emailListRaw.push('ben@com.com')
      this.emailListRaw.push('support@notaproblemforlong.com')
      this.emailListRaw.push('anemail@adoamin.co.uk')
      this.emailListRaw.push('anem+ail@adoamin.com')
      this.emailListRaw.push('another bad email')
      this.emailListRaw.push('and even, with comma')
      this.emailListRaw.push('ben@gmail.com')
      this.emailListRaw.push('ben@benllong.com')
      for (let i = 0; i < 50; i++) {
        this.emailListRaw.push('anemail@adomain.com')
      }
    },
    async sanitizeEmails() {
      let timestart = Date.now()
      this.isRunning = true
      this.progressMessage = 'Removing non-emails and duplicates...'

      let acceptedEmails: EmailObject[] = []
      const rejectedEmails: EmailObject[] = []
      const emailDomains: string[] = []
      // const supportRoles imported above
      // const badDomains imported above
      // const disposableDomains imported above

      const emailListDistinct = [...new Set(this.emailListRaw)] //"Set()" dedups
      this.badEmailTypeCount.duplicate =
        this.emailListRaw.length - emailListDistinct.length

      let count = 0
      //check formatting
      emailListDistinct.forEach((email) => {
        let emailObj: EmailObject = {
          email: email,
          handle: email.split('@')[0],
          domain: email.split('@')[1],
          tld: email.split('.').pop(),
          error: '',
        }

        // limit processing to one million emails
        if (count > 1000000) {
          return
        }

        // if(/^\w+([-.+]?\w+)*@\w+([-]?\w+.)*(\.\w{2,24})+$/.test(email) === false) {
        if (
          /[A-Za-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])/.test(
            emailObj.email
          ) === false
        ) {
          //bad format
          emailObj.error = 'BAD FORMAT'
        }
        if (roleAddresses.indexOf(emailObj.handle) !== -1) {
          //email has support role
          emailObj.error = 'SUPPORT ROLE'
        }
        if (emailObj.tld && badTlds.indexOf(emailObj.tld) !== -1) {
          //email has bad top-level domain
          emailObj.error = 'BAD TOP LEVEL DOMAIN'
        }
        if (badDomains.indexOf(emailObj.domain) !== -1) {
          //email has domain on exclude list
          emailObj.error = 'ON BAD DOMAIN LIST'
        }
        if (disposableDomains.indexOf(emailObj.domain) !== -1) {
          //email has domain on exclude list
          emailObj.error = 'ON DISPOSABLE DOMAIN LIST'
        }
        if (emailObj.error.length > 0) {
          rejectedEmails.push(emailObj)
          this.badEmailTypeCount.badFormat++
          return
        }

        acceptedEmails.push(emailObj)
        emailDomains.push(emailObj.domain)

        count++
      })
      const emailDomainsDistinct = [...new Set(emailDomains)] //de-dups because javascript sets can only have unique values

      //debug messages
      console.log('emailDomains', emailDomains.length)
      console.log('emailDomainsDistict', emailDomainsDistinct.length)
      console.log('Before MX Duration: ', (Date.now() - timestart) / 1000)

      // check mx records
      this.progressMessage = 'Checking MX records of domains...'

      // emailDomainsDistinct.length = 12000

      // chunks the array of domains so that the emailDomainMXVerify can have smaller bites to eat (so it doesn't time out especially)
      const emailDomainsArrayOfArrays = []
      while (emailDomainsDistinct.length) {
        emailDomainsArrayOfArrays.push(emailDomainsDistinct.splice(0, 2000))
      }

      // now feed the mxVerify beast
      const mxPromises: Promise<firebase.functions.HttpsCallableResult>[] = []
      const validateMxCall = firebase
        .functions()
        .httpsCallable('emailDomainMxVerify')
      emailDomainsArrayOfArrays.forEach((emailDomainArray) => {
        mxPromises.push(validateMxCall({ emailDomains: emailDomainArray }))
      })

      // collect what MxVerify returns (should be any good domains)
      // TODO: consider sending back the bad domains since that would be a smaller array to check against, most likely
      let domainsValidMx: string[] = []
      await Promise.all(mxPromises)
        .then((results) => {
          results.forEach((arrayPart) => {
            // console.log('arrayPart', arrayPart.data)
            domainsValidMx = domainsValidMx.concat(arrayPart.data)
          })
          // console.log('domainsMxResolved', domainsValidMx)
        })
        .catch((error) => {
          console.log(error)
        })

      // debug messages
      console.log(
        'MX validation call complete: ',
        (Date.now() - timestart) / 1000
      )
      console.log('domainsValidMx length:', domainsValidMx.length)
      // let badDomainMX = []
      // domainsValidMx.forEach( (domain) => {
      // 	if(domain.indexOf('Error') > -1) {
      // 		badDomainMX.push(domain)
      // 	}
      // })
      // console.log(badDomainMX)

      this.progressMessage =
        'Checking emails against domains with bad MX records...'

      let mxAcceptedEmails: EmailObject[] = []
      acceptedEmails.forEach((emailObj) => {
        if (domainsValidMx.indexOf(emailObj.domain) !== -1) {
          mxAcceptedEmails.push(emailObj)
        } else {
          emailObj.error = 'BAD MX RECORD'
          rejectedEmails.push(emailObj)
          this.badEmailTypeCount.badMXRecord++
        }
      })
      acceptedEmails = mxAcceptedEmails

      console.log('good emails', acceptedEmails)

      this.progressMessage = 'Complete!'
      this.goodEmails = acceptedEmails
      this.badEmails = rejectedEmails
      this.isRunning = false

      console.log('Total Duration: ', (Date.now() - timestart) / 1000)

      // this.progressMessage = 'Checking against known good emails...'
    },
    importCsvFile() {
      //other validation here
      //also client side validation...
      if (this.csvImport) {
        this.emailListRaw = []
        this.goodEmails = []
        this.badEmails = []
        const reader = new FileReader()
        reader.onload = () => {
          const readResult = reader.result as string
          this.emailListRaw = readResult.trim().split(/\r?\n/g) // split on new lines

          // remove any non-standard characters from first (i.e. BOM)
          let input = this.emailListRaw[0]
          let output = ''
          for (let i = 0; i < input.length; i++) {
            if (input.charCodeAt(i) <= 127) {
              output += input.charAt(i)
            }
          }
          this.emailListRaw[0] = output
        }
        // start reading the file. When it is done, calls the onload event defined above.
        reader.readAsBinaryString(this.csvImport)
      }
    },
    exportEmailList(emailObjList: EmailObject[]) {
      const csvLines: string[] = []
      emailObjList.forEach((emailObj: EmailObject) => {
        let line =
          '"' +
          emailObj.email +
          '"' +
          ',"' +
          emailObj.handle +
          '"' +
          ',"' +
          emailObj.domain +
          '"' +
          (emailObj.error ? ',"' + emailObj.error + '"' : '')
        csvLines.push(line)
      })
      // console.log('csvlines', csvLines)
      const csvContent = csvLines.join('\n').replace(/(^\[)|(\]$)/gm, '')

      const csvBlob = new Blob([csvContent], {
        type: 'text/csv;charset=utf-8;',
      })

      var url = URL.createObjectURL(csvBlob)

      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', 'export.csv')
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
  },
})
