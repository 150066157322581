/* eslint-disable prettier/prettier */
export const disposableDomains: string[] = [
'opayq.com',
'mypacks.net',
'mailexpire.com',
'spamex.com',
'iname.com',
'mailinator.com',
'mailinator.net',
'sharklasers.com',
'grr.la',
'guerrillamail.biz',
'guerrillamail.com',
'guerrillamail.de',
'guerrillamail.net',
'guerrillamail.org',
'guerrillamailblock.com',
'spam4.me',
'sneakemail.com',
'consultant.com'
]