/* eslint-disable prettier/prettier */
export const badDomains: string[] = [
'com.com',
'08dnc.com',
'1.com',
'1022.com',
'180glencoe.com',
'1voiceww.net',
'236.com',
'2moromedia.com',
'3220entertainment.com',
'3cm.net',
'3dsquare.com',
'3rdcc.ord',
'432jklsds.com',
'43j2lk432.com',
'4dvision.net',
'4mtnair.com',
'4obiden.com',
'4v.net',
'51yahoo.com',
'5675900.com',
'594designs.com',
'69yahoo.com',
'80202condoforlease.info',
'9etherclothing.com',
'a.o.l.com',
'a0l.com',
'aameritech.net',
'aaol.com',
'aavip.org',
'abe21.net',
'abondanza.us',
'acsd14.k12.co.us',
'actionpartners.com',
'acwmedia.com',
'adairables.com',
'adelphicorp.com',
'adriennejonespr.com',
'aerocareuse.com',
'afrc.af.mil',
'african-voices.net',
'afsouth.nato.int',
'afspc.af.mil',
'agedwards.com',
'agriculture.senate.gov',
'ahhhhh.com',
'ahoo.com',
'aiellopublicreations.com',
'ail.com',
'aimmktg.com',
'aio.oreg',
'aironline.com',
'akdill.com',
'alabamabiz504.com',
'alakondre.nl',
'alaska.com',
'albrechtgro.com',
'albrechtgrp.com',
'alemintertional.com',
'aliregroup.com',
'all.com',
'allabuzz-ci.com',
'allianceforcolorado.org',
'allophone',
'allphasesproduction.com',
'ALLSTUDENTS.COM',
'alltel.com',
'alltel.net',
'allusmag.com',
'alo.com',
'altitudebeverages.com',
'amedd.army.mil',
'ameliacg.com',
'americanpolycom.com',
'americansummitmortgage.net',
'amfam.come',
'ammbc.org',
'ampsindy.com',
'amstarfluids.com',
'amydkell.com',
'amylivingston.com',
'anatvco.org',
'andrewbostic.com',
'andylewisnyc.com',
'angleandassociates.com',
'annapolis.net',
'ansll.net',
'ansllc.net',
'ao9l.com',
'AOAL.CDOM',
'aok.com',
'aol.cim',
'aol.cm',
'aol.co',
'aol.colm',
'aol.com.com',
'aol.comd',
'aol.come',
'aol.comm',
'aol.comno',
'aol.comp9',
'aol.comq',
'aol.comteacherspet',
'aol.con',
'aol.coom',
'aol.dom',
'aol.ocm',
'aol.om',
'aol.vom',
'aolc.om',
'aoll.com',
'aolo.com',
'aom.com',
'aoo.com',
'aop.com',
'ap.net',
'apartment5d.com',
'apcglobal.net',
'apl.com',
'apolloalliance.org',
'apollorealestate.com',
'aptna.org',
'aqol.com',
'archstownmail.com',
'arelem.net',
'armp.org',
'armstrong.eu',
'armstrongmaccall.com',
'armyedcenter.com',
'arnoldlangrand.com',
'arodriguezlaw.com',
'arrowtechnologiesllc.com',
'artec.us.com',
'artistshowcase.com',
'artmanagement.us',
'artofdecorating.net',
'ascol.net',
'asdasadasdasdsasdasdasdasd',
'asianinitiatives.org',
'aspendevelopment.com',
'associatedcontent.com',
'atenandassociates.com',
'atheltics.ucla.edu',
'athena-marketing.de',
'atk.com',
'atlantiscommunityinc.com',
'atpa.com',
'att.ne',
'att.ner',
'att.net.com',
'att.netq',
'att.netz',
'att.ney',
'att.nrt',
'atudent.umass.edu',
'atwtpgp.com',
'aug.edu',
'auiinc.com',
'auoragov.org',
'auruagov.org',
'aveone.com',
'ayahoo.com',
'ayhoo.com',
'ayncsc.net',
'azalumni.com',
'azcar.com',
'azmediaentertainment.com',
'b.com',
'bailysallied.com',
'banjocinema.com',
'bannis.com',
'barbaralawton.com',
'barnonecolorado.com',
'barronl.com',
'batesandmays.com',
'bayh.senate.gov',
'bayuadindustries.org',
'bcglobal.net',
'bcrwealth.com.com',
'bdgarch.com',
'bdigc.com',
'beentherecaughtthat',
'bellsouoth.net',
'bellsouth.bet',
'bellsouth.ent',
'bellsouth.ne',
'bellsouth.neta',
'bemllp.com',
'bennelson.senate.gov',
'benwestlund.com',
'bergercreative.com',
'bergstromlaw.com',
'bethanytribune.net',
'bethel-college.edu',
'bethhinkle.com',
'bfewc.com',
'bigcreekevents.com',
'bigelow.com',
'biggfatz.com',
'bighousesports.us',
'bigplanet.com',
'bigtreepedicab.com',
'billespartners.com.com',
'billyhoward.info',
'bingaman.senate.gov',
'biobizpartners.com',
'birchbrookpress.info',
'blackaustindemocrats.net',
'blackberrieslounge.com',
'blair.edisonschools.com',
'blakeandmelanie.com',
'blalal.com',
'blindbrook.net',
'blinn.bucs.edu',
'blockchaplau.com',
'bloodyfiasco.com',
'bloomou.edu',
'blue.unco.edu',
'bluelinepub.com',
'bluemuesmusic.com',
'bluemusemusic.com',
'bluestreakconnect.com',
'bmtillinois.net',
'bnfcounsel.com',
'bobsautobodyandpaint.com',
'bonfirecreatives.com',
'bootsconstrution.com',
'botmail.com',
'bourassas.com',
'boxer.senate.gov',
'bpace.k12.ca.us',
'brainderd.net',
'brashertech.com',
'brashierfosscpa.com',
'bresanan.net',
'bresman.net',
'brianbaum.net',
'brightoncollegiate.org',
'brightonps.27j.k12.co.us',
'britcondenver.com',
'broadwaywirelesspcs.com',
'broganlegal.com',
'brokenwindmill.com',
'brtsecurity.net',
'brucesinney.com',
'bu.du',
'buckley.af.mil',
'buckleyassociates.com',
'buckmam.com',
'budgetdenver.com',
'buffalo.com',
'buffalobeast.com',
'burkeoneil.com',
'burning-sage.com',
'burrislebed.com',
'busby-carrollteam.com',
'buyblue.org',
'buysandassociates.com',
'byrd.senate.gov',
'c.com',
'cable.comcast.net',
'cablecomm.com',
'cabrientertainment.com',
'cadtwr.ci.detroit.mi.us',
'calendarclu.com',
'calpronotaries.com',
'calrockx.com',
'camcast.net',
'camden.k12.nj.is',
'camdendc.com',
'campus.hpu.edu',
'canovision.net',
'capabilities.com',
'capitalties.com',
'capsizedesign.com',
'car.r.com',
'cardmail.dom.uab.edu',
'carm-a.com',
'carmelcs.com',
'carmelden.com',
'carolwaughquilts.com',
'carpedrm.com',
'carpentersjatc.org',
'Carr',
'cartersands.com',
'casugaservices.com',
'cathymagnificopowers.com',
'cca-usa.com',
'cci.edu',
'cciltc.net',
'ccnewspapers.com',
'cdomlaw.com',
'cebrige.net',
'cecenviro.org',
'centennielpr.blackberry.com',
'centerforemergingmedia.org',
'centerlineafla.com',
'centermarkconsulting.com',
'centurytell.net',
'ceresorganic.com',
'ces.com',
'cgx2.com',
'chaoscancelled.com',
'charneskie.com',
'charte.net',
'charterinternet.com',
'chartierhome.com',
'chccnet.org',
'chegnet.net',
'cheiftain.com',
'chenzo.net',
'cheriseross.com',
'cherrycreekgmac.com',
'cherrystreetschools.org',
'cheyennemountain.af.mil',
'chicagobancorp.com',
'chicagogsb.edu',
'chickybabeproductions.com',
'chrisandmark.net',
'christinejennings2008.com',
'ci.newark.nj.com',
'cimcast.net',
'cincirr.com',
'citiapartments.com',
'citiyofwestminster.us',
'citynetsdsl.com',
'cityofleland.com',
'ckimagewear.com',
'ckruzic.com',
'clanmcq.com',
'clarian.org',
'clarity-coaching.net',
'clarityperspectives.com',
'classicictyproperties.com',
'clmcast.net',
'cloudcreek.org',
'cmcast.net',
'cmg-credit.com',
'cms.mail.virginia.edu',
'cnp-news.net',
'cns.canwest.com',
'co.adams.co.us.com',
'co.edu',
'coaslcio.org',
'cobuck.ang.af.mil',
'cocmast.net',
'coface-trm.com',
'coffeesolutions.net',
'cojcast.net',
'cokcast.net',
'coldcrowspeaks.com',
'coldironhamrick.com',
'collaborativesolutions.us',
'collegesummit.net',
'collier-byrd.net',
'colloradocollege.edu',
'colo.edu',
'coloadocollege.edu',
'coloardocollege.edu',
'colocu.com',
'colon-collawn.com',
'coloradlflatwork.com',
'coloradocollege.eu',
'coloradocommercialre.com',
'coloradof.edu',
'coloradoforchange.com',
'coloradoforchange.org',
'coloradoforethics.org',
'coloradofoudation.org',
'coloradohomestop.com',
'coloradoleage.org',
'coloradoonemortgage.com',
'colorado-relo.com',
'coloradospringsnaacp.org',
'coloragocollege.edu',
'colostate-pueblo.edu',
'colourstv.org',
'colrado.edu',
'colradoalum.org',
'columbus.rr.com.com.com',
'comacst.net',
'comast.net',
'comcaat.net',
'comcadt.net',
'comcaet.net',
'comcas.net',
'comcasg.net',
'comcasr.net',
'comcasst.net',
'comcast.con',
'comcast.ent',
'comcast.et',
'comcast.ne',
'comcast.net19095',
'comcast.netd',
'comcast.netq',
'comcast.ney',
'comcast.nrt',
'comcast.nt',
'comcast.nte',
'comcast.org',
'comcastt.net',
'comcasy.net',
'comcat.net',
'comcawt.net',
'comcqst.net',
'comcsat.net',
'comcsst.net',
'comcst.net',
'comczst.net',
'comdast.net',
'comecast.net',
'commfluent.com',
'commk.com',
'community-collective.com',
'comvast.net',
'comxast.net',
'concast.net',
'conceptmny.com',
'conceptngineer.com',
'congerarchitects.com',
'connectionsil.com',
'cooradohomecomfort.com',
'cophotomed.com',
'cordillera.net',
'corneliawick.com',
'cornishanddelolio.com',
'corporateofficeimages.com',
'costseg-tax.com',
'covver-clark.com',
'cownet.biz',
'cox.bet',
'cox.nex',
'cpmcast.net',
'c-properties.com',
'cra.laciity.org',
'craigziesman.us',
'creativelearninglearning.com',
'cresapartner.com',
'cresteddbutte.net',
'croninandassoc.com',
'crwenterprises.net',
'crwinc.net',
'csintermedia.com',
'csupomon.edu',
'csupomona.edu',
'cubiodiesel.org',
'cudenver.edu',
'cue-nyc.com',
'cummingslawpc.com',
'currentgroup.com',
'curriefam.net',
'customofficesolutions.net',
'cybox.com.com',
'daelin.us',
'dallyworks.com',
'dancerdigital.com',
'danielasrouch.com',
'danielpaulwalker.com',
'danny-b.org',
'dapac.org',
'davcolorado.com',
'davcolorado.org',
'dbr.om',
'dcje.org',
'dcwacllp.com',
'dddstudios.com',
'debbiemarquez.com',
'debbieortega.com',
'DEBSTEELE.NET',
'dellmail.com',
'delnortepix.com',
'deltaforcecap.com',
'demconvetion.com',
'democraticsouth.org',
'democratsabroad.com.ar',
'dems2008.com',
'dems4realamerica.com',
'denishkline.com',
'denvehospice.com',
'denver.co.us',
'denverawpu.com',
'denverchmaber.org',
'denverfinancelab.com',
'denvergals.com',
'denvergod.org',
'denvergv.org',
'denvermedpeds.com',
'denvernewpaperagency.com',
'denvernewspaperagaency.com',
'denverrep.com',
'denverretailgroup.com',
'denverventureschool.org',
'dh-llc.us',
'didenver.net',
'digitalifepc.com',
'dillandill.com',
'dimanna-jackson.com',
'dipitypr.com',
'dippity-doo.com',
'disaster-solutions.net',
'district51.net',
'djaylucid.com',
'djmle.com',
'dnb.con',
'dnc.gov',
'do.usbr.gov',
'doby.biz',
'doctormeridith.com',
'docuvault.com',
'doesnothaveemail.com',
'dolezalek.net',
'domcast.net',
'domicokyle.com',
'donnaedwardsforcongress.com',
'donor-awareness.org',
'dougalscatering.com',
'down2earthadventures.com',
'downsboro.com',
'dps.k12.org',
'drcraiger.com',
'drdue.com',
'dresnicklaw.com',
'drs-ept.com',
'drted2ucnc.net',
'dsavannahtribune.com',
'dss.co.arapahoe.co.us',
'duffanphelps.com',
'dwfincial.net',
'dyahoo.com',
'ealtors.org',
'eam-mates.com',
'earhtink.net',
'earlycm.com',
'earthandskygardens.com',
'earthlink.edu',
'earthlink.ne',
'easementcenter.com',
'easy.net',
'eathlink.net',
'eberbros.com',
'eberharddistributing.com',
'ebomag.com',
'ebonycolorado.net',
'eccoecco.com',
'echogreen.org',
'echswest.org',
'ecofuelworldwide.com',
'editorsrwe.com',
'edlopez.net',
'eeofcolorado.com',
'ef-den.org',
'effco.k12.co.us',
'efficientgroup.com',
'eindstream.net',
'ejchosting.com',
'elanaboses.com',
'elieanddavid.com',
'email.cudenver.edu',
'embarqmail.com.com',
'emciu.com',
'emcosolutions.com',
'emmausdenver.org',
'emontaguebikes.com',
'empgr.com',
'employeradvice.com',
'encryptx.com',
'energy310.com',
'englewood.k12.co.us',
'enzymeent.com',
'epmszp.hu',
'epsyd.es',
'equitylendingllc.net',
'ericadenver.com',
'esmas.net',
'esquirecom.com',
'et-law.com',
'euiwc.com',
'eunet.yu',
'evanbayh.com',
'evisionusa.com',
'exclsuiveresorts.com',
'experiencecoloradosprings.com',
'f.com',
'fabstash.com',
'fairchildpub.com',
'fairhavenstrategy.com',
'falconhfg.com',
'familiesusa.orf',
'fcbcolo.com',
'fcj.com',
'ffc8.oeg',
'fidelium10.com',
'fieldswolfememorialfund.com',
'finmtg.com',
'fjwconsulting.net',
'fk-plus.com',
'flixonthefax.com',
'flmedianews.com',
'flourmilllofts.com',
'flycurrents.com',
'fmail.com',
'fmaiol.com',
'forscom.army.mil',
'forwardcolorado.com',
'foster08.com',
'franklinpierce.edi',
'franlinpierce.edu',
'freightsavers.com',
'frintierhomes.oom',
'friscoskicondo.com',
'frontier.com.com',
'fsrgv.com',
'fuckyou.com',
'fudosys.cm',
'functionalmethods.com',
'fuquaandyoir.com',
'fuquaandyori.com',
'fuse.Netflix',
'fusionfitness.biz',
'futurecampaigns.com',
'fwcons.com',
'g.com',
'g24i.com',
'gahaganlc.com',
'gail.com',
'gallaudett.edu',
'gamial.com',
'gaobama.com',
'garfieldre2.k12.co.us',
'gayzettedenver.org',
'gbail.com',
'gbsrlaw.com',
'gbvlaw.com',
'gccnj.edu',
'georgiaprime.com',
'geostarsolar.com',
'germano.biz',
'gethip.biz',
'getknown.com',
'ggail.com',
'ggmail.com',
'ghvalley.ne',
'giftservicesinc.com',
'gimail.af.mil',
'gimail.com',
'gimal.com',
'gipperdesign.com',
'gjail.com',
'gjn.com',
'gkail.com',
'glacierwebservices.com',
'globalcupcoffee.com',
'globalenergy.com',
'gloriahardington.com',
'gluckey.com',
'glynnfinancialsys.com',
'gmaail.com',
'gmai.com',
'gmai.coml',
'gmaii.com',
'gmaiil.com',
'gmaik.com',
'gmail.c',
'gmail.ca',
'gmail.can',
'gmail.ccom',
'gmail.cim',
'gmail.cm',
'gmail.co',
'gmail.co.uk',
'g-mail.com',
'gmail.com.au',
'gmail.com.com',
'gmail.com.com.com',
'gmail.com.mx',
'gmail.com10456',
'gmail.comj',
'gmail.coml',
'gmail.comnku',
'gmail.como',
'gmail.con',
'gmail.coom',
'gmail.copm',
'gmail.cpm',
'gmail.fr',
'gmail.h.com',
'gmail.net',
'gmail.nl',
'gmail.ocm',
'gmail.om',
'gmail.vom',
'gmail.xom',
'gmailc.om',
'gmailcom.com.com',
'gmaill.com',
'gmailo.com',
'gmaim.com',
'gmaip.com',
'gmaiul.com',
'gmajl.com',
'gmakl.com',
'gmal.com',
'gmali.com',
'gmall.com',
'gmamil.com',
'gmaol.com',
'gmaul.com',
'gmeil.com',
'gmial.com',
'gmIl.co',
'gmil.com',
'gmqil.com',
'gmsail.com',
'gmsil.com',
'gmwil.com',
'gmzil.com',
'gnail.com',
'gnmail.com',
'goeaton.net',
'goingourway.net',
'goldenmountainacupuncture.com',
'goldsys.com',
'golfviews.com',
'gomail.com',
'goodwilleastersealsmv.org',
'googlemail.co.uk',
'googlemaile.com',
'googlmail.com',
'gordonroark.com',
'gotmail.com',
'gourmetfinecatering.com',
'gpd.nl',
'graland.ord',
'gram.cc',
'grandluxerail.com',
'grandmapurple.com',
'grandparentforobama.com',
'grandparentsforobama.com',
'greatwesternartgallerylic.com',
'greenconvenestrategies.com',
'grijalvaforcongress.com',
'gripchurch.com',
'grizmail.umt.edu',
'gshllc.com',
'gt.rr.com.com',
'gtisupport.com',
'gttma.com',
'gwec.com',
'gwm.name',
'h.com',
'hackstaffgessler.com',
'hahoo.com',
'hakdjh.com',
'hankyoungrealestate.co',
'harborproperties.com',
'harry2008.com',
'harter.net',
'hcisjc.org',
'healthcareunited.org',
'heatly.com',
'heaven.org',
'heavenandnature.com',
'heinzmangroup.com',
'helmsgreene.com',
'hendersonisd.org',
'herc.com',
'heroesoffiath.net',
'herrycreekschools.org',
'heymannlawfirm.com',
'hgnsconsulting.om',
'hidalgodemocrats.org',
'highlandpark.k12.nj.us',
'highplains.biz',
'highrocksells.com',
'hildebrandtewes.com',
'hill.af.mil',
'hillandrobbin.com',
'hindssg.com',
'hispanicstrategygroup.com',
'hitmail.com',
'hkh-law.com',
'hltmail.com',
'hmail.com',
'hmconsulting.org',
'hmji.net',
'hoail.com',
'hoamail.com',
'hodgesconsulting.com',
'hogmail.com',
'hohtmail.com',
'holandhart.com',
'holly.colostate.edu',
'homail.com',
'homebrew.com',
'homeguru.com',
'homeopathyforwellness.com',
'homtail.com',
'hoperoadconsulting.com',
'hormail.com',
'hosue.mi.gov',
'hotamail.com',
'hotamil.com',
'hotjail.com',
'hotkail.com',
'hotmai.com',
'hotmaik.com',
'hotmail.cm',
'hotmail.co',
'hotmail.cok',
'hotmail.colm',
'hotmail.com.com',
'hotmail.comf',
'hotmail.comi',
'hotmail.comj',
'hotmail.coml',
'hotmail.comm',
'hotmail.comt',
'hotmail.con',
'hotmail.coom',
'hotmail.cpm',
'hotmail.cvom',
'hotmail.omc',
'hotmaill.com',
'hotmaim.com',
'hotmain.com',
'hotmaiol.com',
'hotmaip.com',
'hotmakl.com',
'hotmal.com',
'hotmaol.com',
'hotmaul.com',
'hotmedia.us',
'hotmiail.lcom',
'hotmial.com',
'hotmIl.com',
'hotmqil.com',
'hotmsil.com',
'hotmzil.com',
'hotnail.com',
'hotrmail.com',
'hotspring-steamboat.com',
'hottmail.com',
'house.mail.gov',
'houses-123.com',
'hoymail.com',
'hptmail.com',
'hrpartnersinc.com',
'htomail.com',
'hudsonstreetmedia.com',
'hughes.met',
'hughesagency.org',
'hughs.net',
'hustonisd.rg',
'hwstudents.com',
'hyahoo.com',
'hyperdrnsity.net',
'iaaaacame.org',
'iamdl143.org',
'iantoni.co',
'iaobama.com',
'ibewlocal118.com',
'ibfree.net',
'iccrents.com',
'icloud',
'iclould.com',
'icloyd.com',
'icoud.com',
'idaho-democrats.org',
'idahoforobama.org',
'idahostew.com',
'idcwebdev.com',
'idir.net',
'igabrie.com',
'igssinc.com',
'ihopeyouunderstand.com',
'il.com',
'ildblue.net',
'imageworksok.com',
'imagina.com',
'imahomo.com',
'imco.com',
'imediahawaii.com',
'imedihawaii.com',
'immediate.com',
'imoguel.com',
'impactpartnersllc.com',
'in.com',
'indiarts.com',
'indigio.com',
'industrialedgesupply.com',
'inhhres.com',
'innobative-industries.org',
'innworldreport.com',
'insighbb.com',
'insitudesign.org',
'insivghtbb.com',
'intela.com',
'interkan.nat',
'intermountain-rea.com',
'international.edu',
'intrmtn.com',
'intuitsolutionsintl.com',
'inwardmotion.com',
'ioud.com',
'irizmcc.com',
'irwl.com',
'is.mines.edu',
'isaacdavisjr.com',
'isscorp.us',
'itt-tech.edu',
'ittvis.com',
'iupatdc1.org',
'ive.com',
'iwantmy.com',
'iwon.com',
'iyahoo.com',
'jackieberger.om',
'jamesjackson.com',
'jan4kc.com',
'jasonmcmahan.net',
'jayegipson.com',
'jcbpartners.com',
'jcfkk.com',
'jeffco.co.k12.us',
'jefferson.co.us',
'jeffreylawoffices.com',
'jennisonconst.com',
'jensoncom.com',
'jhotmail.com',
'jimandcynthia.com',
'jimdoyle2010.com',
'jjimaldinger.com',
'jki-online.com',
'jlnonthehill.com',
'jnli.com',
'joannetwomey.com',
'jobfitpros.c0m',
'jodimoraru.com',
'joerossetto.com',
'johnsonbruen.com',
'jollyrissler.com',
'jonesradio.net',
'jordancoombs.com',
'jotmail.com',
'jpadillalaw.com',
'jrlloydlaw.com',
'jtaxsc.com',
'junglemate.com',
'just6dollars.org',
'justplainpoetry.com',
'jwhalldesign.com',
'jwmfibers.com',
'kandbsource.com',
'kandumedia.com',
'kathyhartman.org',
'katpub.com',
'katrinaalliance.org',
'kbbolaw.com',
'kbno.com',
'kc.rr.cm',
'k-campbell.com',
'kcfpc.com',
'keanetel.com',
'kefalas2008.com',
'kenexa.com',
'kennedycomp.com',
'kennedyforri.com',
'kevcarr.com',
'kfwb.com',
'kgmb9.com',
'kimstire.com',
'kippla.edu',
'kirshnercommunications.com',
'kkey11.com',
'kkpllp.com',
'klmtaxlaw.com',
'kmbconsulting.us',
'kragerandassociates.com',
'KrouseForAssembly.com',
'kskynet.be',
'ksrlaw.com',
'ktglegal.com',
'ktglegla.com',
'kubiiunlaw.com',
'kutarock.com',
'kvdco.com',
'l-3.com.com',
'labor2008.org',
'ladhs.org',
'lamar.cocostate.edu',
'lancastergeneral.com',
'landam.com',
'landeregalgroup.com',
'landmarkeducation.net',
'landry-creedon.com',
'lapook.con',
'lathlenconsulting.com',
'latinainitiative.org',
'latinpublications.com',
'LauraLA.org',
'lauraswork.com',
'lautenberg.senate.gov',
'lawcapitalinc.com',
'lbldc.org',
'lcloud.com',
'ldaho.biz',
'lebowlaw.com',
'leeolds.org',
'leesdiscountautobody.com',
'lefthandnetworks.com',
'legacyunlimited.net',
'lekyconsulting.com',
'leonardwalsh.com',
'letdaviddoit.com',
'letstalkfamily.net',
'level-5marketing.com',
'levey2006.com',
'liberty.com',
'libertysportsgroup.com',
'lifespolitical.com',
'lightonfinance.com',
'lincoln.senate.gov',
'lindseysam.net',
'lisachristianproductions.com',
'listenuppr.com',
'littlegreenviper.com',
'littlevoice.org',
'live.com.com',
'live.com44120',
'live.comm',
'livingstonesunlimited.com',
'lkkpartners.com',
'll.net',
'llightspeed.com',
'llprecords.com',
'lnsdesigns.net',
'localinsightmedia.com',
'lonestarsettlements.com',
'longmont.com',
'Lopes',
'lopezcheung.com',
'lrsmedia.net',
'lscottspears.com',
'luckttfamily.org',
'lunacie.com',
'lupusnwoh.org',
'luxestuff.com',
'lwps.org',
'lyahoo.com',
'lympus.net',
'lynch08.com',
'lyneezajacbeck.com',
'lysiuslaw.com',
'm2000corp.com',
'mac.com.com',
'mackenziehouse.net',
'madebyindra.com',
'magnet.at',
'maguireproperties.com',
'mahogany-group.com',
'mail.accd.edu',
'mail.ccsd.k12.co.us',
'mail.colorado.edu',
'mail.com.com',
'mail.com.gov',
'mail.houe.gov',
'mail.house.gvo',
'mail.org',
'maine.rr.com.com',
'maineforchange.org',
'mainvoice.org',
'mall.bradley.edu',
'map-lab.com',
'marcyemberger.com',
'maricopademocrat.com',
'marisol.com',
'markandsamanthamcdonald.net',
'markeyforcongress.com',
'marymount.k12.ny.us',
'marzanoandassociates.com',
'masonnetworks.net',
'massageservices.net',
'massyoungdems.org',
'mathlearningi.com',
'mattmidgette.com',
'mbc.edu',
'mbx.mainichi.co.jp',
'mcapitol.com',
'mcdpnaldag.com',
'mcduff.com',
'mce.gouv.gc.ca',
'mcg.edu',
'mcgihonlaw.com',
'mcgraw-hill.com',
'mcks.net',
'mcsd.edu',
'mcsqd.com.com',
'mdd701.com',
'mdelanoconsulting.com',
'mdfcommunications.net',
'me.com.com',
'media.astral.com',
'mediasafe.org',
'mediashpere.com',
'medicia.com',
'medvetcomm.com',
'megmiller4art.com',
'mendezsteadman.com',
'mercury-ldo.com',
'meritcare.com',
'mertchantsmtg.com',
'mervyns.com',
'mesa.k12.co.usa',
'mesastate.edu',
'mesatate.edu',
'metal-res.com',
'metro.edu',
'metrodw.com',
'mezaconstruction.com',
'mfpdc.com',
'mgmoinjurylaw.com',
'mgnb.net',
'mhccl.org',
'mhotmaill.co',
'mhreonline.com',
'mhtc.nt',
'miamipuntocom.net',
'mickeyandmoran.net',
'microbusiness.org',
'midsummermedia.com',
'mifamilavota.net',
'mikebroemmel.org',
'mikeyoungproperties.com',
'mikeysmells.com',
'mindspring.com',
'mindwspring.com',
'minyard.edu',
'miraclemortgage808.com',
'mistarfinancial.com',
'mjohnsonimages.net',
'mocampaignforchange.org',
'mockstars.com',
'moers.us',
'molliecullom.com',
'morgenthelar.com',
'moshersullivan.com',
'mostchoice.com',
'mprthwestern.edu',
'mri.gouv.qc.edu',
'msatfamily.com',
'mscb.edu',
'mscd.edu',
'msm.com',
'msmc.la.edu',
'msn.co',
'msn.com.com',
'msn.comr',
'msn.comt',
'msn.con',
'msn.net',
'msn.om',
'msn.oom',
'msn.vom',
'msnc.om',
'msnn.com',
'murphy08.com',
'murphybusiness.com.com',
'mwcd.edu',
'my.ursa.edu',
'mybrighthouse.com',
'myclearwave.net',
'myersconsulting.org',
'myhelio.com',
'myibocs.com',
'mymail.indstate.edu',
'mymail.roosevelt.edu',
'myoffice-eagle.com',
'mypacks.net',
'myportal.montana.edu',
'myshoo.com',
'myway.com',
'mywdo.com',
'mywirelessinc.com',
'naihpc.com',
'natca.nt',
'nationalcity.com',
'navteq.com',
'nbni.tv',
'ncmail.net',
'ncobama.com',
'ncol.col.com',
'neacharter.org',
'nedernet.ent',
'nelsonchapel.com',
'Neo.rr.com.com',
'neoucom.edu',
'nerdchick.com',
'nestlingtours.com',
'netgenious.org',
'netunification.com',
'netway.net',
'net-wright.com',
'netzer.com',
'netzert.net',
'neuromark.com',
'neurotechlearn.com',
'newbillco.com',
'newjerusalembaptist.com',
'newjournalist.org',
'newplatz.edu',
'newspapersfirst.com',
'newtonbusinesslaw.com',
'nextlevelmechanicals.com',
'nf.sympaticol.ca',
'ngt.com',
'nh-access.com',
'nikitsonfas.com',
'nikkilamagna.com',
'njc.com',
'njforobama.com',
'nlrsd.k12.ar.us',
'nm.net',
'nmail.com',
'nmforchange.com',
'nmforobama.com',
'nnwif.com',
'noa.gov',
'noaah.org',
'noemail.com',
'northernassociates.net',
'notgivingout.com',
'notmail.com',
'notreal.trump',
'nrcbroadcasting.com',
'nrgbydesign.com',
'nrgi-us.com',
'nuwesthomes.com',
'nysenatedems.org',
'nysite.net',
'oaklandzoo.com',
'oal.com',
'obama.senate.gov',
'obamacampaign4change.org',
'obamafd.com',
'obamajewelry.org',
'obamamaine.us',
'obamawyoming.com',
'occupiedspace.com',
'octoberstrategies.com',
'ofl.org',
'ogn.af.mil',
'ohea.or',
'ojai.net',
'ol.com',
'olyahoo.com',
'omtare.org',
'oneearthsolutions.com',
'oneil3.com',
'oneofonellc.com',
'onion.com',
'onlynatuiralpet.com',
'on-naton2.com',
'onshore.net',
'opayqe.com',
'opeiudc.org',
'oponline.net',
'opportunitytechnology.com',
'oprodigy.net',
'opt.com',
'optomlime.net',
'optonine.net',
'optonlin.net',
'optonline.ent',
'opusnw.com',
'or.state.us',
'orarden.net',
'oregonvos.net',
'orionesi.com',
'osterlund-a.net',
'otmail.com',
'ouranthemhighlands.net',
'outlook.com.com',
'overton-group.com',
'ovi.com',
'p23movement.com',
'pacbell.bet',
'pacell.net',
'paleaders.org',
'palloneforcongress.org',
'paradigmpilgrim.com',
'parentiacconting.com',
'parentiaccounting.com',
'parkercodemocrats.org',
'parkerlanellc.com',
'parksidetechnologyservices.com',
'paschaldevelopment.com',
'patelforgeorgia.com',
'patttydetroit.com',
'pawneestar.com',
'pcmaz.com',
'pcmillersales.com.com',
'pcrehomes.com',
'pdvi.org',
'peakdem.org',
'peakdems.org',
'pearlstreetdental.cpm',
'penberg.com',
'pennacol.com',
'pennellabuilders.com',
'pennyhaun.com',
'pentico.net',
'peoplepc.com.com',
'peoriaroe48.net',
'pepnet.org',
'perlmutterforcolordo.com',
'peterson.af.mil',
'pgnusa.net',
'phlcherner.com',
'phubby.com',
'phxic.com',
'pilatesblue.net',
'pinionmgt.com',
'pixeldustpress.com',
'pixelthief.tv',
'pjc.edu',
'pkselective.com',
'plainstell.com',
'plantexusa.com',
'plattteriver.com',
'plumberslocal3.com',
'png.canwest.com',
'poetrycardsusa.com',
'pointblankpa.com',
'pol.net',
'polarcomm.com.com',
'politacaldg.com',
'politickeroh.com',
'polyphasia.com',
'powermort.com',
'pravado.com',
'precorind.com',
'presidentreed.com',
'pride.hostra.edu',
'princetone.edu',
'proactivellc.com',
'prodailog.org',
'professorwhalen.com',
'progressivebookclub.com',
'progressivecapitol.com',
'progressivemajority.og',
'progressivepatriotsfund.com',
'progressiveresourcegroup.com',
'progressivestrategypartners.com',
'projectvote.org',
'prokupeks.com',
'propensity.us',
'protandim.com',
'protectcoloradsfuture.org',
'prudentialcresco.com',
'psausa.org',
'psci.nett',
'pselectricinc.com',
'psigroupinc.com',
'psualum.com',
'punkass.com',
'purelypolitical.us',
'purpleflowerspa.com',
'push-point.com',
'pws-law.com',
'q.com.com',
'qaol.com',
'qeddata.com',
'qindstream.net',
'qol.com',
'queest.net',
'quentin.hope.name',
'quest.om',
'qwesoffice.net',
'qwest.ent',
'qwestinternet.net',
'qwetoffice.net',
'qyahoo.com',
'racehustler.com',
'raicorp.net',
'randyjones.us',
'rangelcampaign.org',
'rangeweb.neet',
'rap.midco.ent',
'rathole.org',
'ravenwindwindjewelry.com',
'rbsginc.com',
'rconcepts.biz',
'rdsherr.com',
'rebel.net-tec.co.us',
'rebirthmusicminsitries.com',
'redevresearch.com',
'regentmedia.com',
'remingtonandfriends.com',
'remoteimage.com',
'renewablegroup.cpm',
'res-america.com',
'reserveenergycorp.com',
'reson8.com',
'resys1.com',
'retinalalliance.com',
'revereberatemedia.com',
'reversekbar.com',
'rgis.edu',
'rha-firea.com',
'richardsonreporting.com',
'rickalbertson.net',
'rickandgary.com',
'ridgelight.net',
'rightoffcenter.com',
'rippleeffectrelations.com',
'risngstarmbc.com',
'rjfengineering.com',
'rmail.com',
'rmamb.com',
'rmamgmt.com',
'rmgmo.com',
'rmredev.com',
'rmscdenver.org',
'rnrc.net',
'rnrdesign.net',
'roadtunner.com',
'roanokelifecburch.rog',
'robins.af.mil',
'rockewelch.com',
'rockforbarack2008.org',
'rodgersmail.net',
'rodkin',
'rogetteharris.net',
'ross49.org',
'rossfbo.com',
'rossilegalstaffing.com',
'rossmillerphoto.com',
'rostamcaptial.com',
'rothberger.com',
'rreal-law.com',
'rscogc.org',
'rtveamerica.com',
'rushanddivison.com',
'rushfinancialservices.com',
'rzx.us',
'saalaw.us',
'sacredorigins.org',
'sad6.k12.me.us',
'saferhealthcare.com',
'sah.ocm',
'salazar.senate.gov',
'salazar2008.com',
'salud.edu',
'sampson-roberts.com',
'samyoon.com',
'saturnjax.com',
'saugov.sa.gov.au',
'savageproedits.com',
'savstate.edu',
'sbcglbal.net',
'sbcglibal.net',
'sbcglobal.bet',
'sbcglobal.com',
'sbcglobal.ent',
'sbcglobal.met',
'sbcglobal.neet',
'sbcglobal.ner',
'sbcglobal.nert',
'sbcglobal.netg',
'sbcglobal.nwt',
'sbcglobl.net',
'sbcgloble.net',
'sbcgobal.net',
'sbchglobal',
'sbcuc.net',
'sbdesignstudeo.com',
'sbglobal.net',
'scarlettgroupe.com',
'scbglobal.net',
'scbgolbal.net',
'schenkein.com',
'schoolhousecom.com',
'schriever.af.mil',
'schuetze-gordon.com',
'schulzephotography.com',
'scott.af.mil',
'scotternet.net',
'sdccu.net',
'sdpapc.com',
'sdumd.edu',
's-e2.com',
'sea2summitadventureguides.com',
'seabornconsulting.com',
'sedonarocksjewelry.com',
'seiiu32bj.org',
'seiulocal1877.org',
'semalogy.com',
'senado.bo',
'senate.state.ts.us',
'sevensinteractivemedia.com',
'sfmarkets.com',
'sfsonline.co.za',
'shapins.com',
'shbroadcastservices.com',
'sheridan.k12.co.us',
'sherrysteele.com',
'shiny25.com',
'shumstein.com',
'sickbastard.com',
'sierranevaada.edu',
'sigidesign.com',
'signlanguage.com',
'similatedpeople.com',
'simla.colostate.edu',
'simla.colostate.org',
'simplybathrooms.us',
'simplybits.net',
'sinauthor.com',
'sindstream.net',
'sinekpartners.com',
'sipfusion.com',
'siroccofilms.com',
'skaddenc.om',
'skimpact.com',
'skipe.dor.state.co.us',
'skipmulch.com',
'slamtry.com',
'slflaw.com',
'slovay.com',
'smdc.org',
'smithlell.com',
'smw88.com',
'snclobal.net',
'socal.rr.com.com',
'sol.com',
'solbourne.com',
'soporis.net',
'sourcemdx.com',
'southfacing.biz',
'sovereignairinc.com',
'sovo.com',
'spamcop.net',
'spaminton.com',
'speedtrail.net',
'spencerfoxlaw.com',
'spiritoftravel.com',
'spirtoftravel.com',
'spokeswomanproduction.com',
'sportseventplanner.com',
'spring-usa.com',
'sprintpcs.com',
'spudstogo.com',
'srsbiofuels.com',
'sry.edu',
'ssam.homeip.net',
'st.bemidjistate.edu',
'staceyweisser.com',
'standfordalumni.org',
'standingrock.com',
'starband.net',
'starbulletin.com',
'starprousa.com',
'state.co.edu',
'state.com.us',
'statebanktrust.com',
'station20.org',
'staufferlawcpa.com',
'stercomm.com',
'steveeaton.net',
'stevegladfelter.com',
'stolenmixtape.com',
'stratstaff.net',
'stribmail.com',
'strongamericanschools.com',
'stu.beloit.edu',
'student.ccbcmd.edu',
'student.stonehill.edu',
'students.sunyjefferson.edu',
'studentsforbarackobama.com',
'sudddenlink.net',
'summitcoachingllc.com',
'sunflowre.com',
'sunmountain.tv',
'sunncast.net',
'sunpowerinc.org',
'swishmark.com',
'sympatico.va',
'synapsemail.com.com',
'taceventcorporation.com',
'tahio.com',
'tahoo.com',
'taic.net',
'talonsadventures.com',
'tampabay.rr.com.com',
'tampabay.rr.comm',
'tanguito.org',
'tanyakellybowry.com',
'tarikcoles.com',
'tatibroadcasting.com',
'tauntonlibdems.org.uk',
'taylormassage.com',
'tceq.state.tx.us',
'tchden.org',
'tcinternet.ent',
'tdwcc.org',
'teacher.com',
'teamsterslocal814.com',
'telecomservicesinc.com',
'teleflip.com',
'tellisministries.org',
'telus.bet',
'templeinland.com',
'tenasset.com',
'terraspark.com',
'tesorosltd.com',
'test.com',
'test.test',
'test2.com',
'test2.comq',
'texasnature.net',
'tfb.com',
'tfcd.org',
'theadbarn.com',
'theamanigroup.com',
'thebandinc.com',
'thechingdom.com',
'theclintongroup.com',
'thecommuniquegroup.com',
'thecruzfoundation.com',
'thecutlergroup.net',
'thedelegates.net',
'thedenvernewspaperagency.com',
'thedyslexiacenter.com',
'the-gatheringplace.org',
'thegruners.com',
'thehouseofgilead.org',
'theivorygarage.com',
'thejasons.com',
'thejstandard.com',
'thelouisianasapphirenews.com',
'themacrinagroup.com',
'themartinishop.net',
'thementoringctr.org',
'themetroapts.com',
'themilestonegoup.com',
'themtn.tv',
'theothersideart.com',
'thepennyinstitute.org',
'thepucelgroup.com',
'therehab.com',
'thereileygroup.com',
'thereindls.com',
'theriviera.com',
'thespeechstation.com',
'thewashingtongroup.com',
'thinkwa.org',
'thirdreich.gov',
'thomasson.us',
'thompson.k12.co.us',
'thornton.saco.org',
'threegracesdesign.com',
'threesixtynm.com',
'tiaa-cref.ort',
'tim-browm.com',
'timespicayune.com',
'timeusa.com',
'tinapamintuan.net',
'tiny.net',
'tipe208.com',
'tirnanogproductions.com',
'tkci.com',
'tlcnanniesinc.com',
'tmail.com',
'tmo.blackberry.com',
'tn-mcc.org',
'toast-jam.com',
'tobe-schnur.com',
'toddsaliman.com',
'toledosnd.irg',
'tonyamjones.com',
'totmail.com',
'town.ridway.co.us',
'trademarkcommunities.com',
'traitor.com',
'translatedin24.com',
'travala10.com',
'travelingmiller.com',
'travisanddenise.com',
'tresins.com',
'trevordoherty.com.au',
'triad.r.com',
'trinidadcopd.org',
'truatman-shreve.com',
'trujillogroup.net',
'tugboatpr.com',
'turnstrends.com',
'turtleexpedition.com',
'twcable.com',
'twtelecom.com',
'tyahoo.com',
'tympany.net',
'uack.org',
'uahoo.com',
'ubp-usa.com',
'ubrestored.org',
'uchsc.edu',
'uchsc.org',
'udallforcolorado.com',
'uioaw.edu',
'ukremb.com',
'understorypictures.com',
'unita.us',
'unitedafa.orf',
'unitedforpeace.oeg',
'unitedinternational.us',
'united-securities.com',
'unitedstatesthinktank.us',
'unlv.ecu',
'unlvrebels.com',
'uno.com',
'unrnevada.edu',
'urbanmgmt.com',
'urrecoach.com',
'us.ing.com',
'us.nextaff.com',
'usahockey.com',
'usatyger.com',
'uscfmedctr.org',
'usrim.com',
'ust-bulk.com',
'usw.salavationarmy.org',
'utdemocrats.org',
'utpa.edu',
'utsla.com',
'utsouthwestern.rdu',
'uyahoo.com',
'vaastugrace.com',
'vailskivactions.com',
'vailtrail.com',
'vain-online.com',
'valenciacm.com',
'vbs.vi',
'vdh.vrginia.gov',
'vendorsofcolorado.com',
'ver.net',
'verison.net',
'verixon.net',
'verizion.net',
'verizo.net',
'verizon.ent',
'verizon.ner',
'verizon.nt',
'verizon.nwr',
'verizonmail.com',
'vernonjonesforsenate.com',
'villuame.com',
'virtalweb.net',
'virtualmediaonline.com',
'visitbhcc.com',
'vitalmed.com',
'vividplanetfilms.com',
'vk',
'vlcdenver.com',
'vmail.com',
'vmdirect.com',
'vomcast.net',
'vorlaw.com',
'votecorroon.com',
'voxdeserto.com',
'vrak12.org',
'vtsi.biz',
'vzavenue.net',
'wachoviasec.com',
'wadawilliams.com',
'wademortgage.com',
'wagnerinvestment.com',
'waljones.com',
'wanadoo.nl',
'wanu.net',
'warpdriveonline.com',
'warperiveonline.com',
'washcall.edu',
'wasington.cbc.ca',
'waterdropworkshop.com',
'wbsd.ms.us',
'wcgnet.net',
'wdemail.com',
'webbpatlaw.com',
'wegenfamily.com',
'weidemannassoc.com',
'welchertandbritz.com',
'wellsfargois.com',
'wesleymotivational.com',
'westernstrategies.org',
'westmedco.com',
'westneckcreek.com',
'wetsteste.com',
'wexlerwexler.com',
'wheelsworth.net',
'whitehorsebooks.net',
'whitehouse.gov',
'wibdstream.net',
'wickedwool.net',
'widgetinc.com',
'widnstream.net',
'wihdstream.net',
'wildnlue.net',
'willtoor.org',
'wimdstream.net',
'wincstream.net',
'windatream.net',
'winddtream.net',
'windetream.net',
'windsgream.net',
'windsrream.net',
'windsteeam.net',
'windsteram.net',
'windstfeam.net',
'windstgeam.net',
'windstraem.net',
'windstreak.net',
'windstrean.net',
'windstreqm.net',
'windstresm.net',
'windstrezm.net',
'windstteam.net',
'windsyream.net',
'windwtream.net',
'winemail.net',
'winestream.net',
'winfstream.net',
'winin08.org',
'winndstream.net',
'winningelections.us',
'winsstream.net',
'winstream.net',
'wirelesschannels.net',
'wisdomofwellnessproject.com',
'wishamandassociates.com',
'wizardsbarn.com',
'wjnews.com',
'wkndstream.net',
'wnidstream.net',
'wohlcomm.net',
'wonderfuldenver.com',
'wondstream.net',
'woodrow-sobel.com',
'wordoc.com',
'workinglandscapes.com',
'worldet.att.net',
'worldnet.att.edu',
'worldney.att.net',
'worldtitle.net',
'wrhlaw.com',
'wtcu.com',
'wundstream.net',
'wvdhhr.org',
'xcelengrgy.com',
'xceptional.biz',
'xomcast.net',
'xtensionservices.com',
'xxmission.com',
'xyloenegy.com',
'y7ahoo.com',
'yaahoo.com',
'yaboo.com',
'yafoo.com',
'yaghoo.com',
'yagoo.com',
'yah.com',
'yah00.com',
'yahaa.com',
'yahaoo.com',
'yahgoo.com',
'yahh.com.com',
'yahho.com',
'yahhoo.com',
'yahhoo.com.com',
'yahii.com',
'yahio.com',
'yahioo.com',
'yahlo.com',
'yaho.com',
'yaho0o.com',
'yahoi.com',
'yahol.com',
'yahoo.ccom',
'yahoo.cim',
'yahoo.cjom',
'yahoo.cm',
'yahoo.co',
'yahoo.co.ui',
'yahoo.cok',
'yahoo.com.cm',
'yahoo.com.com',
'yahoo.com.com.com',
'yahoo.com07860',
'yahoo.comd',
'yahoo.comi',
'yahoo.comk',
'yahoo.comm',
'yahoo.comp',
'yahoo.comsi',
'yahoo.con',
'yahoo.coym',
'yahoo.net',
'yahoo.ocm',
'yahoo.om',
'yahoo.oom',
'yahoo.org',
'yahoo.som',
'yahoo.xom',
'yahoo0.com',
'yahooc.com',
'yahooi.com',
'yahool.com',
'yahoom.com',
'yahoomail.com',
'yahooo.com',
'yahooo.com.com',
'yahoop.com',
'yahoord.com',
'yahop.com',
'yahopo.com',
'yahpo.com',
'yahpoo.com',
'yahpp.com',
'yajhoo.com',
'yajoo.com',
'yanhoo.com',
'yanoo.com',
'yaoo.co.uk',
'yaoo.com',
'yaool.com',
'yaqhoo.com',
'yatoo.com',
'yayoo.com',
'ydarkansas.org',
'ydhoo.com',
'ydnv.org',
'yhahoo.com',
'yhaoo.com',
'yhaooo.com',
'yhoo.com',
'yhoo.net',
'yhool.com',
'yhooo.com',
'ymai.com',
'ymail',
'ymail.com.com',
'yoahoo.com',
'yohoo.com',
'yoshizzle.net',
'you.cheats',
'youhoo.com',
'yourname.net',
'yqhoo.com',
'ysahoo.com',
'yshoo.com',
'ytahoo.com',
'yuahoo.com',
'yuhoo.com',
'ywhoo.com',
'yyahoo.com',
'yzhoo.com',
'zaborenko.com',
'zeeba.org',
'zenbe.com',
'zol.com',
'ZYGOCOM.COM',
'11thhourfurniture.com',
'1226gmail.com',
'1250Eye.com',
'22gmail.com',
'24hourft.com',
'250fifthave.com',
'3dshopping.com',
'3tcss.com',
'40trinity.edu',
'7mail.com',
'a.com',
'abbeyeinery.com',
'abdalla.com',
'ac.com',
'accessbee.com',
'accidebtlawkc.com',
'accurexeng.com',
'act-pa.com',
'acutech-consuting.com',
'adttsa.com',
'advjkl.nek',
'aeseinc.com',
'afim.com',
'agoradesignpartnership.com',
'ail.con',
'alberhaskylaw.com',
'allthingmurphy.com',
'altavista.com',
'altavista.net',
'alummi.ucla.edu',
'alvinsher.net',
'ameliora.co',
'andersonor.com',
'angelasivili.com',
'ansbacherus.com',
'ants.edu',
'aol.ccom',
'AOL.CLOM',
'aol.com.NOEMAIL',
'aol.coma',
'aol.comb',
'aol.coml',
'aol.comSusan',
'aol.comv',
'aol.cpm',
'aol.l.com',
'aola.com',
'aolston.com',
'apexgroupofcompanies.om',
'apollogrp.edu',
'apstate.us',
'ardelisllc.com',
'arfnbark.com',
'arnold.cmu.edu',
'arriwsprings.com',
'artassets.con',
'artcamoa.com',
'ArtMale.com',
'asdfs.cz',
'ashlandcreekl.net',
'askdrpat.net',
'assistedchoice.com',
'atoneyoga.com',
'att.nett',
'att.nt',
'audata.co.uk',
'aumaim.averett.edu',
'austin.rr.con',
'azcopy.com',
'azfowlers.com',
'bachomedia.com',
'baddleymauro.com',
'ballartdspahr.com',
'bandtdist.com',
'barberfalcone.com',
'basc.net',
'bassham-rayl.com',
'BayAreaCRS.com',
'bbchb.com',
'bbeckman.com',
'befdg.com',
'beginhesling.com',
'belinkoffandsimon.net',
'bellevuefamilydental.com',
'bellsbellsouth.net',
'bellsouth.nete',
'bellsouth.ney',
'bennettmurphylaw.com',
'beresnan.net',
'berkeleuy.edu',
'berkeleyresists.us',
'best-setvice.com',
'betizon.met',
'betsymicek.com',
'betzlaw.net',
'bgleasondesign.com',
'biffduncan.ccom',
'bigdrumart.com',
'bigskt.net',
'bio.bu.edu',
'boardwalk.com',
'bobdodge.org',
'bohemianvalley.com',
'boserforpastatesenate.com',
'brandeia.edu',
'browbnbradshaw.com',
'browneassa.com',
'brtc.met',
'brysam.com',
'btbluewin.com',
'bucksdemhq.com',
'burridgelaw.com',
'bzliving.org',
'c2glaw.com',
'c4miles.tv',
'cabeon.net',
'calamusa.net',
'capitolgate.com',
'capstoneag.com',
'carlsbadopenspace.com',
'CarolGrieshaber.com',
'carolina.re.com',
'casbeokers.com',
'catalysta.com',
'caterflydolutions.com',
'ccfamilymed.con',
'ccmca.com',
'cctr.umkc.edu',
'cedarbaum.net',
'cesmtp.ccf.org',
'cespitzer.com',
'cfi.ucsb.edu',
'cfl.com',
'cfl.rr',
'cfl.rr.cm',
'cgelectrodes.com',
'cgfinan.com',
'charter.comnet',
'charter.comney',
'charter.met',
'charter.netp',
'charter.ney',
'charter.nwt',
'chaslevy.com',
'chcolk.com',
'chemgate.chem.lsu.edu',
'christinazone.com',
'clarityconceptinc.com',
'cleanshotcom.com',
'clear.net',
'clintonstreetgroup.com',
'coachnickray.com.com',
'cofon.com',
'cognitivearts.com',
'colchester-creek.net',
'colorafodreams4life.com',
'columbiaa.edu',
'comcast.cet',
'comcast.met',
'comcast.neet',
'comcast.neg',
'comcast.ner',
'comcast.neth',
'comcast.netj',
'comcast.netm',
'comcast.netP',
'comcast.nnet',
'commercialsolardesign.net',
'computology.biz',
'comvadt.net',
'congressionalcapitalmanagement.com',
'consecodirect.com',
'coosatrc.com',
'cooumbus.rr.com',
'corkc.com',
'co-sales.com',
'coulmbusrr.com',
'coumbia.edu',
'cox.comnet',
'cox.met',
'cox.netT',
'coyotewash.com',
'cpmpaint.com',
'crab.rutgers.edu',
'creativeprtnersllc.com',
'crecraft.netc',
'critterswoods.org',
'cruzyo.com',
'cs.texase.edu',
'cscs.uky.edu',
'csu.fullerton.ede',
'csufrlesno.edu',
'ct.metrocast.net',
'ctrc.us',
'cwa13000.org',
'cwmercer.com',
'cwnet.com',
'dao.mit.edu',
'davechapoell.net',
'davepoop.com',
'davidawolf.com',
'davidkuebler.net',
'dcbbk.com',
'dcx.com',
'ddylanw.net',
'dempasen.gov',
'DianaCrawford.com',
'dimensiontx.co',
'dkhanson.com',
'documaniafilms.com',
'dsl-only.net',
'dsurewest.net',
'dsviddayre.com',
'dufferdad.com',
'duke.esu',
'duncaninterests.com',
'duvalandassociates.com',
'dzsells.vom',
'earlpickens.com',
'earthlink.ner',
'earthlink.nett',
'earthlink.nety',
'earthlink.ney',
'earthlnk.nt',
'easystreet.nrt',
'ekertseamans.com',
'eldock.org',
'electmikelake.com',
'elizabethbirchcompany.com',
'ellenberglawoffices.com',
'ellen-ink.com',
'ellentauscher.com',
'elwha.hsn.us',
'email.arizon.edu',
'email.arzona.edu',
'email.sfcc.edu',
'emilylist.org',
'emsh.calarts.edu',
'envirosciencegroup.com',
'epix.ne',
'ericding4congress.com',
'es.nemc.org',
'ewcomfund.org',
'excite.comm',
'explorerinvestors.com',
'ezing.pro',
'facstaffwisc.edu',
'fangermen.com',
'fast.colorado.edu',
'fbw.com',
'feeltheloveauatin.com',
'fertel.con',
'firstinscience.org',
'flinjury.net',
'fnet.net',
'forcej.com',
'frcr.biz',
'fredjutch.org',
'fridainc.org',
'froebel.us',
'frontier.comy',
'frontier.et.net',
'fuse.netMargaret',
'g.comail.com',
'g.mail.com',
'gail.om',
'garrettcreekranch.com',
'gateway.net',
'gene.con',
'gentics.med.harvard.edu',
'genworthltc.com',
'georgiahealth.edu',
'geosciuchicago.edu',
'ghendry.com',
'ghsglobal.com',
'globalearospace.us',
'globalinclusionstrategies.com',
'gma03il.com',
'gmaii.comm',
'gmail.coim',
'gmail.col',
'gmail.coma',
'gmail.comc',
'gmail.comcom',
'gmail.comd',
'gmail.come',
'gmail.comengineer',
'gmail.comhi',
'gmail.comjuju',
'gmail.comlo',
'gmail.comlook',
'gmail.comm',
'gmail.comments',
'gmail.comn',
'gmail.comni',
'gmail.comp',
'gmail.comq',
'gmail.comr',
'gmail.comRL',
'gmail.coms',
'gmail.comsa',
'gmail.comt',
'gmail.comx',
'gmail.fom',
'gmail.gom',
'gmail.lcom',
'gmail.ocom',
'gmail.oom',
'gmail.org',
'gmails.com',
'gmaul.con',
'gmmail.com',
'gmsil.cim',
'gmsil.con',
'goldenbergcandy.com',
'goldsteinmgy.com',
'grant.phys.subr.edu',
'graysonhighlands.net',
'greenhiljrwers.com',
'greenroxpartners.com',
'grouptgree.net',
'growerswhouse.com',
'gursey.baruch.edu',
'gw1.mr',
'haha.nope',
'hammondshinnets.com',
'harmoniousdrsign.com',
'harrellandjohnson.com',
'hbayllc.com',
'hclarchitecture.cm',
'heakthmaxme.cim',
'healthrecovery.orh',
'hes.hmc.psu.edu',
'hfmb.com',
'hhooncompanies.com',
'hiddengulch.com',
'hillsandstern.com',
'hi-road.net',
'hitachi-hra.com',
'hitnaik.com',
'hlotmail.com',
'hmsil.com',
'hmwdres.org',
'home.com',
'hotail.com',
'hotmail.ccom',
'hotmail.clm',
'hotmail.comAli',
'hotmail4.com',
'how.gi.alaska.edu',
'howardztx.com',
'hp47.net',
'hpesrce.com',
'hsc.uth.edu',
'hsllawyers.com',
'huffman-associates.com',
'hughesandlane.net',
'hvc.rr',
'hypertype.com',
'hzpartners.com',
'ibarraassociates.com',
'ibkank.tv',
'ibm.net',
'ic.metcom.com',
'ic.netcom.vom',
'icloud.comj',
'icloud.comp',
'icloud.net',
'illinous.edu',
'illlinois.edu',
'independencestrategy.com',
'inetforless.net',
'inetmail.att.net',
'infusiondev.com',
'innernet.com',
'intetcapital.net',
'ipfw.edu',
'iquemagazine.com',
'isc.rr.com',
'ivmhealth.org',
'jammgrm.com',
'janehicks.com',
'javenco.com',
'jenkinsinsgroup.com',
'jewel.morgan.edu',
'jewishminmeapolis.org',
'jgmail.com',
'jhu.edo',
'jmp-dc.com',
'joe.gov',
'joepascucci.com',
'johnrefior.com',
'jonesauger.co',
'jordandanielselectric.com',
'jttierney.com',
'Judaol.com',
'judthandjim.com',
'judymahbey.com',
'juliabell.com',
'jumpnet.com',
'juno.comr',
'juno.comy',
'kaija.com',
'karenwinnick.com',
'kdkmgt.com',
'keaconsultingservices.com',
'kedemcapital.cm',
'kellyandmonaco.com',
'khattabmd.com',
'kingstonpeterson.com',
'kitchenguy.biz',
'knoxforphilly.com',
'ku.eduDo',
'kua.net',
'kuykendallassociatesllc.com',
'kynge.us',
'lanankist.com',
'landfillsplus.com',
'lasalleasset.com',
'latinmail.com',
'law.vilianova.edu',
'leporefinearts.com',
'levyangstreich.com',
'libertysquaregrp.com',
'ligjtweabers.com',
'LINCOLN-CLEAN-ENERGY.COM',
'littlebrowncats.com',
'liz.com',
'llezelle.com',
'llpplaw.com',
'logmodctr.com',
'lorainesimons.com',
'lostpointsllc.com',
'love-your-planet.com',
'loyno.edu',
'ltsg.edu',
'ltsp.edu',
'lucymcbath.com',
'lycosmail.com',
'mac.boisestate.edu',
'mac.come',
'mac.con',
'maggie-zine.com',
'magnoliaroad.net',
'mail.cc.trincoll.edu',
'mail.con',
'mail.harvardedu',
'mail.tju.edu',
'mail.trc.upenn.edu',
'mail.widener.edu',
'mail.wm.edu',
'maine.rrcom',
'majoritygroupdc.com',
'makindus.com',
'marciawoodgaller.com',
'mariecameron.com',
'markriddell.com',
'mas.com',
'maureenmason.com',
'maurermet.com',
'mb1.us',
'mccoolarabians.com',
'mckhof.com',
'mcnallysmith.edu',
'me.cozm',
'meads.cc',
'medicird.com',
'medlin.cm',
'megahydrate.com',
'merrilllawoffice.com',
'meudenver.edu',
'mf.aaom.co',
'michaelblackfordnc.com',
'midlebass.org',
'mimivogel.com',
'mindspring.con',
'mindsring.com',
'mitlns.mit.edu',
'mocmin.org',
'moelawyers.com',
'mogambosolutions.com',
'moneyformulas.com',
'montecitonet.com',
'moodie-mobiles.dks',
'mop.caltech.edu',
'mountairyalpacas.com',
'mrkc.com',
'msmmn.com',
'msn.cim',
'msn.comJUDY',
'msn.cpm',
'mustangesallygroup.com',
'muvabl.com',
'mwagrafx.com',
'my.sfcollege.edu',
'myoacks.net',
'mytalk.com',
'mytu.tuskegee.edu',
'namependng.com',
'ncsbcs.org',
'nekosoftware.com',
'neo.re.com',
'netsoltek.com',
'netzero.coom',
'newburybizpark.comc',
'newellmarketing.com',
'nimbus.temple.edu',
'no.com',
'nomail.com',
'NoneOfTheThree.orgC',
'nonono.gov',
'norteastern.edu',
'nortel.com',
'nthwave.net',
'nucman.physics.fsu.edu',
'nucott.phyiscs.edu',
'nuumi.net',
'nw.opp.psu.edu',
'nwpatx.com',
'nycap.rr.ccom',
'odysseus.uchicago.edu',
'ofsdc.com',
'okstale.edu',
'ole.com',
'olteanu.org',
'omcast.net',
'onebesch.com',
'ophomestay.live',
'optomlibe.net',
'optomline.net',
'optonline.ner',
'optonline.netj',
'ospreys.unf.edu',
'outlook.comm',
'outlook.con',
'OYMPUS.NET',
'pacbel.net',
'pacific.net.hk',
'paearlystage.com',
'PalladianOne.com',
'patientfirst.con',
'paulburkander.com',
'pembrokephilanthy.net',
'permansnyc.com',
'perryjensenlaw.com',
'personaldevelopment.org',
'petrizzostrategic.com',
'phasop.com',
'philadelphia.gov',
'philak12.pa.us',
'philapa.gov',
'philiprush.com',
'philly.gov',
'phippsmoore.com',
'phslawfirm.com',
'phys.subr.edu',
'pi.assurancesys.com',
'pibinbc.com',
'pincusbros.com',
'plakinslaw.com',
'plastictrophy.com',
'pldi.ner',
'plusstrategic.net',
'plymout.edu',
'poldir.com',
'post.harvard.wdu',
'powerelectric.us',
'PreFormulationSolutions.com',
'principle.edu',
'prioryinvest.com',
'prism.gatech.edu',
'prismnnet.com',
'prodigy.ent',
'psu.esu',
'publicprivatepartnershipinc.com',
'pzer.com',
'q.cm',
'Q.om',
'qlpmena.com',
'qualiatica.com',
'raimentchandler.com',
'rainiac.net',
'rampabay.rr.com',
'rampageinc.com',
'randolphmorganconsuiing.com',
'rapidreturnsdigital.com',
'rcalum.com',
'rdatel.com',
'rdblaw.com',
'recarnationabq.com',
'recogmedia.com',
'redhill.net',
'redriverstrategies.com',
'reiter5280.com',
'ResistSF.org',
'returnpathhol.gr',
'rhoodes.edu',
'rickhutcheson.com',
'ritaink.com',
'rivervalleyrealty.nj',
'rljenterntainment.com',
'r-lpierce.com',
'rlresearch.com',
'roadarunner.com',
'roberteblinc.com',
'robertseidelstudio.com',
'rochster.rr.com',
'rocketmail.con',
'rocko.mit.edu',
'rodpallen.com',
'rosemontstrategies.com',
'rothenbergs.ney',
'roxhester.rr.com',
'royalbeeinc.com',
'rpinotes.com',
'rqwpc.com',
'rrhcap.com',
'ruelle.mines.edu',
'safeconnect.com',
'sageadvisorsinc.com',
'san.re.com',
'sandhblaha.com',
'sanrr.com',
'sarah.fiu.edu',
'sargesdvisors.com',
'satx.rr.us',
'sbcglobal.et',
'sbcglobal.ne',
'sbcglobal.netl',
'sbcglobal.ney',
'sbcglobal.nwet',
'sc.rr.edu',
'scclawfirm.com',
'schools.nyc.org',
'schubertgrp.com',
'sciencaparty.org',
'scwstl.com',
'sdma.com',
'se.buffalo.edu',
'seedsys.com',
'shaniphoto.com',
'ShenandoahProductions.com',
'shepbob.com',
'shoobilee.com',
'showtuneproducrions.com',
'siemonlarsen.com',
'sierraclubmass2.org',
'singfine.com',
'sirfbvi.com',
'sjcsf.edu',
'slrin.net',
'smc-esources.com',
'smeietlaw.com',
'smlth.edu',
'sncglobal.bet',
'sncglonal.net',
'snet.ner',
'soapai.co',
'sobraninc.com',
'somecharacter.com',
'sornig.net',
'soundpatientadvocates.com',
'spacesintetiordesign.com',
'spanish-is-essential.com',
'spareetimeinc.com',
'speedoflightvideo.com',
'spiritone.com',
'splawnapkes.com',
'stanford.exu',
'steinerpoint.com',
'stopheartattack.org',
'studleynv.com',
'su.ed',
'sullerm.com',
'sunflowerbc.com',
'supershuttle.net',
'susansheapirdy.org',
'svn.net',
'swpo.com',
'swva.met',
'tacolicious.con',
'taimen.net',
'tampabay.r.com',
'tampabsy.rr.com',
'tandtcomputing.com',
'tarlowdesighn.com',
'teccocorp.com',
'tecny.rr.com',
'tedwallace.com',
'temple.edup',
'teravision-inc.com',
'theatercance.ucsb.edu',
'thebeagroup.com',
'thecontainersource.net',
'thedoas.com',
'theedgewatergroup.net',
'theflourishfoundation.org',
'themoens.org',
'thenewimpressionists.com',
'theoscarphx.com',
'theresistancenetwork.org',
'thurstonboyd.comno',
'tidewatet.net',
'tinteractive.co.uk',
'tks-law.com',
'toacl.com',
'tocky.edu',
'todd-evans.com',
'tonywong.org',
'tops-tel.com',
'tow-path.com',
'trafikads.com',
'trishastark.com',
'troutandsons.com',
'tryline.com',
'tsag.com',
'tsamslaw.com',
'tscaz.con',
'twc.net',
'twcny.com',
'twcny.rr',
'typographicservice.com',
'ub-alumni.org',
'ucdenve.eu',
'ukr.qsi.org',
'uky.ed',
'ultimatefitnessacademy.net',
'umbc.edi',
'umich.edi',
'unicom.com',
'uoregn.edu',
'usdc.us',
'ushoo.com',
'ustrustboston.com',
'utolledo.edu',
'UTSouthwesten.edu',
'va.edu',
'varnerassociates.com',
'veizon.net',
'velidyne.com',
'velojerseys.com',
'verizon.et',
'verizon.ne',
'verizon.neet',
'verizon.ney',
'verizon.nst',
'VERNALIA.ORG',
'viahealth.org',
'vintageaustinhomes.com',
'vistaventures.net',
'visuallink.ccom',
'vomcast.ney',
'voteracquel.com',
'votesn.com',
'vqhdings.com',
'vrizon.net',
'w.com',
'waastaff.com',
'watson.ibm.com',
'wavecablw.com',
'wchtv.net',
'weaverandweaverlaw.con',
'weavingarts.us',
'westseattlewebs.co',
'whirlingpool.com',
'winninginvesting.comn',
'winnmark.copm',
'wi-rr.com',
'wktriallaw.com',
'wmitchell.edu',
'woodsyriallaw.com',
'wordwizard.us',
'world.att.net',
'World.Oberlin.edu',
'wrightimpression.com',
'wrwood.com',
'wsnconsult.com',
'yah6454881oo.com',
'yahh.com',
'yaho.comm',
'yahoe.com',
'yahohh.com',
'yahok.com',
'yahoo.clom',
'yahoo.cojm',
'yahoo.comE',
'yahoo.comg',
'yahoo.comj',
'yahoo.comRichard',
'yahoo.comvom',
'yahoo.coo',
'yahoo.coom',
'yahoo.cpm',
'yahoo.vom',
'yahyoo.com',
'yanoo.cm',
'yerkes.uchicago.edu',
'yHoo.vom',
'YMAIL.COMs',
'yshoo.vom',
'zachai.com',
'zoomimnternet.net',
'zucerman.com'
]